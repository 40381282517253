import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  ptoken: any;
  baseUrl:any ;

  constructor(private injector: Injector) { 
    this.baseUrl = environment.baseUrl
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
    if (JSON.parse(sessionStorage.getItem('access_token')) != null) {
      this.ptoken = JSON.parse(sessionStorage.getItem('access_token'));      
    }

    // const id = request.url.substring(request.url.lastIndexOf('/') + 1);
    // const finalId = parseInt(id);
    
    if (request.url == this.baseUrl+"oauth/token") {
      request = request.clone({
        setHeaders: {
          Authorization: `Basic Y2xpZW50OnNlY3JldA==`,
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS'
        }
      });
    }
    else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.ptoken}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS'
        }
      });
    }

    return next.handle(request);
    // return next.handle(request).pipe(
    //   tap(
    //     event => {},
    //     error => {}
    //   )
    // );
  }
}