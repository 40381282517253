import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit  {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  isLightSelected : boolean = false;
  user: any;
  userDetails: any = [];
  utoken: any = {};
  username: any;
  id: any;
  feed: any[] = [];
  public rows = [];
  timeLeft: number;
  isTeamLead :any ;
  isAdmin : any;
  private subscription;
  currentTheme :any;
  token : any;
  userMenu = [];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private _router: Router) {
    if (this.utoken == null) {
      this._router.navigateByUrl('auth/login');
      this.userMenu = [
        { title: 'Login', link: '/auth/login' }
      ];
    }
    else {
      this.menuService.navigateHome();
      this.userMenu = [
        { title: 'View Profile', link: '/pages/user-profile' },
        { title: 'Change Password', link: '/pages/change-password' },
        { title: 'Log out', link: '/auth/logout' }
      ];
    }

  }

  ngOnInit() {
    this.userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    this.user = this.userDetails.name;

    // this.userService.getUsers()
    //   .subscribe((_users: any) => this.user =  this.userDetails);
    // this.startTimer();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
    if(themeName == 'default'){
      this.isLightSelected = true;
    }else{
      this.isLightSelected = false;
    }
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onAction() {
    this._router.navigate(['/pages/dashboard'])
  }
}
